import React from "react";

class Page extends React.Component {
  render() {
    return (
      <div>
        <div id="fb-root" />
        
        
        <div className="addbg">
          <div className="onas">
            <div className="wrapper onas">
              <header
                style={{
                  height: "120px"
                }}
              >
                <div
                  style={{
                    height: "67px"
                  }}
                >
                  <h1 className="logo">
                    <a href="/" />
                  </h1>
                  <nav>
                    <a href="/site/produkty/" className="link">
                      Produkty
                    </a>
                    <span>| </span>
                    <a
                      href="/site/o-neutrogenie/kim-jestesmy/"
                      className="section"
                    >
                      O Neutrogenie
                    </a>
                    <span>| </span>
                    <a href="/site/bad-piekna/" className="link">
                      Bądź piękna
                    </a>
                    <span>| </span>
                    <a href="/site/see-whats-possible/" className="link">
                      See What's Possible
                    </a>
                    <span>| </span>
                    <a
                      href="/site/skora-z-niedoskonalosciami/"
                      className="link"
                    >
                      Skóra z niedoskonałościami
                    </a>
                    <span>| </span>
                  </nav>
                </div>
                <div
                  style={{
                    "padding-left": "185px",
                    height: "53px"
                  }}
                >
                  <nav
                    className="second"
                    style={{
                      float: "none"
                    }}
                  >
                    <a href="/site/hydroboost/" className="link">
                      Hydro Boost
                    </a>
                    <span>| </span>
                    <a href="/site/skin-detox/" className="link">
                      Skin Detox
                    </a>
                    <span>| </span>
                    <a href="/site/cellular-boost/" className="link">
                      Cellular Boost
                    </a>
                    <span />
                  </nav>
                </div>
              </header>
              <div className="top">
                <h2>
                  O marce
                  <br /> NEUTROGENA<sup>®</sup>
                </h2>
                <div className="breadcrumb">
                  <a href="/">
                    NEUTROGENA<sup>®</sup>
                  </a>
                  <span>&gt;</span>
                  <a href="/site/o-neutrogenie/kim-jestesmy/">
                    O Neutrogenie
                  </a>{" "}
                  <span>&gt;</span>
                  Formuła Norweska
                </div>
              </div>
            </div>
          </div>
          <div className="submenu">
            <div className="wrapper">
              <a href="/site/o-neutrogenie/kim-jestesmy/" className="link">
                Kim jesteśmy
              </a>
              <a href="/site/o-neutrogenie/historia/" className="link">
                Historia
              </a>
              <a
                href="/site/o-neutrogenie/formula-norweska/"
                className="current"
              >
                Formuła Norweska
              </a>
            </div>
          </div>
          <div className="wrapper">
            <div className="content">
              <section>
                <div className="bg form1">
                  <div className="text fullright">
                    <h2>
                      Przełomowa Formuła Norweska w produktach marki NEUTROGENA
                      <span className="super">®</span>
                    </h2>
                    <div className="p">
                      Przełomowym momentem w dziejach firmy okazały się badania
                      nad właściwościami pielęgnacyjno-leczniczymi gliceryny.
                      Jej niezwykłe działanie uznano za idealne rozwiązanie
                      pielęgnujące suchą i zniszczoną skórę. Główną inspiracją
                      przy opracowywaniu nowej formuły były tradycyjne norweskie
                      produkty - balsamy i maści stosowane przez norweskich
                      rybaków w arktycznych warunkach. Dzięki zawartym
                      składnikom skutecznie łagodziły suchość skóry rąk.
                      <br />
                      <br />W wyniku badań naukowych oraz analizy ekstremalnych
                      warunków atmosferycznych, powstała unikatowa receptura
                      Formuły Norweskiej. Idealnie zbalansowane składniki
                      zapewniają:&nbsp;
                      <br />
                      <br />
                      <ul>
                        <li>skuteczną ochronę przed przesuszeniem skóry,</li>
                        <li>intensywne nawilżanie,</li>
                        <li>właściwą pielęgnację skóry</li>
                      </ul>
                      <br />
                      wystawionej na wymagające warunki atmosferyczne.
                    </div>
                  </div>
                </div>
              </section>
              <section>
                <div className="bg form2">
                  <div className="text left">
                    <h2>Formuła</h2>
                    <p>
                      W naszych produktach wykorzystujemy wyselekcjonowane
                      składniki, które zapewniają skuteczną regenerację i
                      głębokie nawilżenie.Za zmiękczenie skóry i utrzymanie
                      optymalnej ilości wody w naskórku odpowiadają w produktach
                      NEUTROGENA<sup>®</sup> emolienty.
                      <br />
                      Jednak dopiero formuła jako całość sprawia, że produkty
                      opatrzone logiem NEUTROGENA<sup>®</sup> Formuła Norweska
                      przenikają przez zewnętrzną powłokę warstwy rogowej
                      naskórka, zapewniając skórze nie tylko skuteczne
                      nawilżenie, ale także regenerację.
                    </p>
                  </div>
                </div>
              </section>
              <section className="last">
                <div className="bg form3">
                  <div className="text right">
                    <h2>40 lat pielęgnacji dłoni</h2>
                    <p>
                      Ich najwyższą jakość i skuteczność potwierdzają lekarze
                      dermatolodzy, którzy rekomendują je swoim pacjentom.
                      <br />
                      Produkcja intensywnie nawilżającego kremu do rąk
                      z&nbsp;Formułą Norweską - NEUTROGENA<sup>®</sup> classic
                      Norwegian Formula Hand Cream ruszyła ponad 40 lat temu, w
                      1972 roku. Dzisiaj świat produktów NEUTROGENA<sup>®</sup>{" "}
                      obejmuje kilkanaście linii kosmetyków przeznaczonych do
                      pielęgnacji rąk, stóp, ciała i ust, w których wykorzystano
                      sprawdzoną i skuteczną Formułę Norweską.
                    </p>
                  </div>
                </div>
              </section>
              <div id="social_id_11"></div>
            </div>
          </div>
          <div className="bluebar">
            <div className="bar">
              <div className="wrapper">
                <a href="/site/o-neutrogenie/kim-jestesmy/">
                  neutrogena<sup>®</sup> obecnie
                </a>
                <span>|</span>
                <a href="/site/o-neutrogenie/formula-norweska/">
                  czym jest formuła norweska?
                </a>
                <span>|</span>
                <a href="/site/o-neutrogenie/historia/">
                  poznaj naszą historię
                </a>
                <span>|</span>
                <a href="/site/produkty/">zobacz produkty neutrogena</a>
              </div>
            </div>
          </div>
          <footer>
            <div className="wrapper">
              <nav>
                <a href="/site/" className="logo2" />
                <a href="/site/nota-prawna/">Nota Prawna</a>
                <a href="/site/polityka-prywatnosci/">Polityka Prywatności</a>
                <a href="/site/cookies/">Polityka cookies</a>
                 <a href="/site/kontakt/">Zachęcamy do kontaktu</a>                   
                           <span>© JNTL Consumer Health (Poland) sp. z o.o. 2023 </span>
            </nav><a id="ot-sdk-btn" className="ot-sdk-show-settings">Ustawienia plików Cookie</a>
              <p>
                Strona zarządzana jest przez JNTL Consumer Health (Poland) sp. z o.o., ul. Iłżecka 24, 02-135 Warszawa. Spółka zarejestrowana w
                Sądzie Rejonowym dla m. st. Warszawy, XIII Wydział Gospodarczy
                Krajowego Rejestru Sądowego, pod numerem KRS 00000322278, NIP
                113-00-20-467, wysokość kapitału zakładowego: 39.751.500 PLN.
                JNTL Consumer Health (Poland) sp. z o.o. ponosi wyłączną
                odpowiedzialność za treści zawarte na niniejszej stronie.
                <br /> Strona jest przeznaczona dla użytkowników z Polski.
                Ostatnia aktualizacja: 01/02/2023
                <br />{" "}
              </p>{" "}
              <p>
                <span>
                  Strona została zoptymalizowana pod kątem przeglądarek:
                  Internet Explorer 7 i wyższych, Mozilla Firefox, Google
                  Chrome, Opera, Safari
                </span>{" "}
                <span className="right">
                  Zalecana rozdzielczość: 1024 x 768 pikseli
                </span>{" "}
              </p>
            </div>
          </footer>
          <noscript
            dangerouslySetInnerHTML={{
              __html:
                '<iframe src="//www.googletagmanager.com/ns.html?id=GTM-N7SHM8" height="0" width="0" style="display:none;visibility:hidden"></iframe>'
            }}
          />
          <script
            dangerouslySetInnerHTML={{
              __html:
                "(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],\nj=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='//www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);\n})(window,document,'script','dataLayer','GTM-N7SHM8');"
            }}
          />
          <script type="text/plain" class="optanon-category-4" 
            dangerouslySetInnerHTML={{
              __html:
                "\n!function(f,b,e,v,n,t,s){if(f.fbq)return;n=f.fbq=function(){n.callMethod?\nn.callMethod.apply(n,arguments):n.queue.push(arguments)};if(!f._fbq)f._fbq=n;\nn.push=n;n.loaded=!0;n.version='2.0';n.queue=[];t=b.createElement(e);t.async=!0;\nt.src=v;s=b.getElementsByTagName(e)[0];s.parentNode.insertBefore(t,s)}(window,\ndocument,'script','https://connect.facebook.net/en_US/fbevents.js');\nfbq('init', '227575234312850'); // Insert your pixel ID here.\nfbq('track', 'PageView');\n"
            }}
          />
          <noscript
            dangerouslySetInnerHTML={{
              __html:
                '<img height="1" width="1" style="display:none" src="https://www.facebook.com/tr?id=227575234312850&amp;ev=PageView&amp;noscript=1">'
            }}
          />
        </div>
      </div>
    );
  }
}

export default Page;
